/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-05-22",
    versionChannel: "nightly",
    buildDate: "2024-05-22T00:05:27.491Z",
    commitHash: "671be1a810640bced86269e09cb050498b6ed18b",
};
